import axios from "axios";

const getToken = () => {
    // console.log("test");
    if (window.localStorage.hasOwnProperty("token")) {
        return window.localStorage.getItem("token");
    } else {
        return "";
    }
};

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 50000,
});

AxiosInstance.interceptors.request.use(
    function(config) {
        config.headers.Authorization = `Bearer ${getToken()}`;
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

export default AxiosInstance;
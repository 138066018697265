// import { Button } from "antd";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
// import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isLoggedIn } from "../../utils/isLoggedIn";
// import Logo from "../../assets/img/logo.png";
import "./header.scss";

export const SHeader = () => {
  let history = useHistory();
  const [drawer, setDrawer] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", function () {
      let offset = window.scrollY;
      // console.log("offset :: ", offset);
      if (offset > 300) {
        setIsNavFixed(true);
      } else {
        setIsNavFixed(false);
      }
    });

    return () => {
      window.removeEventListener("scroll", function () {
        console.log("scroll event removed");
      });
      history = null;
    };
  }, []);

  const list = (anchor) => (
    <div role="presentation" className="font-poppins">
      <List>
        <ListItem button>
          <ListItemText onClick={() => history.push("/")} primary="HOME" />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/about-us")}
            primary="ABOUT US"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/reviews")}
            primary="REVIEWS"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/joindbb")}
            primary="JOIN DBB"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/event-list")}
            primary="EVENTS"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/sponsors-list")}
            primary="SPONSORS"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/opportunities")}
            primary="OPPORTUNITIES"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/job-list")}
            primary="JOB OPPORTUNITIES"
          />
        </ListItem>

        <ListItem button>
          <ListItemText
            onClick={() => history.push("/company-list")}
            primary="COMPANIES"
          />
        </ListItem>

        <ListItem button>
          <ListItemText
            onClick={() => history.push("/members")}
            primary="MEMBERS"
          />
        </ListItem>

        <ListItem button>
          <ListItemText
            onClick={() => history.push("/blog-list")}
            primary="NEWS & MEDIA"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/contact-us")}
            primary="CONTACU US"
          />
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/gallery")}
            primary="GALLERY"
          />
        </ListItem>
      </List>
    </div>
  );
  return (
    <header>
      <div
        className={`header__wrapper font-montserrat ${
          isNavFixed ? "header__nav-fixed" : ""
        }`}
      >
        <div style={{ minHeight: "100px" }}>
          <img
            src="/dbb_header_logo.jpeg"
            alt=""
            className="img-fluid cursor-pointer"
            style={{ width: "100px" }}
            onClick={() => history.push("/")}
          />
        </div>
        <div className="header__navbar">
          <div onClick={() => history.push("/")} className="header__navbarText">
            HOME
          </div>
          <div
            // onClick={() => history.push("/about-us")}
            className="header__navbarText position-relative"
          >
            <span className="d-flex align-items-center">
              <span>ABOUT US</span> <i class="fas fa-chevron-down ml-1"></i>
            </span>
            <div className="position-absolute header__navbar-dropdown">
              <div
                onClick={() => history.push("/about-us")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                About Us
              </div>
              <div
                onClick={() => history.push("/gallery")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                Gallery
              </div>
              <div
                onClick={() => history.push("/joindbb")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                Join DBB
              </div>
              <div
                onClick={() => history.push("/reviews")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                Reviews
              </div>
            </div>
          </div>
          {/* <div
            onClick={() => history.push("/joindbb")}
            className="header__navbarText"
          >
            JOIN DBB
          </div> */}
          <div
            onClick={() => history.push("/event-list")}
            className="header__navbarText"
          >
            EVENTS
          </div>
          <div
            onClick={() => history.push("/sponsors-list")}
            className="header__navbarText"
          >
            SPONSORS
          </div>
          <div className="header__navbarText position-relative">
            <span className="d-flex align-items-center">
              <span>OPPORTUNITIES</span>
              <i class="fas fa-chevron-down ml-1"></i>
            </span>
            <div className="position-absolute header__navbar-dropdown">
              <div
                onClick={() => history.push("/job-list")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                Job Opportunities
              </div>
              <div
                onClick={() => history.push("/opportunities")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                Business Opportunities
              </div>
              {/* <div
                onClick={() => history.push("/company-list")}
                className="header__navbar-dropdown-item py-2 px-2"
              >
                Companies
              </div> */}
            </div>
          </div>
          {/* <div
            onClick={() => history.push("/job-list")}
            className="header__navbarText"
          >
            JOB OPPORTUNITIES
          </div> */}

          <div
            onClick={() => history.push("/members")}
            className="header__navbarText"
          >
            MEMBERS
          </div>

          <div
            onClick={() => history.push("/company-list")}
            className="header__navbarText"
          >
            COMPANIES
          </div>
          <div
            onClick={() => history.push("/services")}
            className="header__navbarText"
          >
            SERVICES
          </div>
          <div
            onClick={() => history.push("/blog-list")}
            className="header__navbarText"
          >
            NEWS & MEDIA
          </div>
          <div
            onClick={() => history.push("/contact-us")}
            className="header__navbarText"
          >
            CONTACT US
          </div>
          {/* <div
            onClick={() => history.push("/gallery")}
            className="header__navbarText"
          >
            GALLERY
          </div> */}
        </div>
        <div className="header__navbar_mob">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
          {list("right")}
        </Drawer>
      </div>
    </header>
  );
};

import "./mainLoader.scss";

export default function MainLoader() {
  return (
    <div className="mainloader__wrapper">
      <div className="mainloader__box">
        <div className="mainloader__container">
          <span className="mainloader__circle"></span>
          <span className="mainloader__circle"></span>
          <span className="mainloader__circle"></span>
          <span className="mainloader__circle"></span>
        </div>
      </div>
    </div>
  );
}

// import { Button } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import "../assets/css/services.scss";
import Layout from "../Layouts/layout";
import Search from "../stories/search/search";
import ServiceCard from "../stories/ServiceCard/ServiceCard";
import AxiosInstance from "../utils/axiosInstance";

export default function Services() {
  const [services, setServices] = useState([]);
  const [searchService, setSearchservice] = useState();

  useEffect(() => {
    getServices();
  }, []);

  // TO GET ALL SERVICES

  const getServices = () => {
    AxiosInstance.get(`${process.env.REACT_APP_BASE_URL}services`)
      .then((res) => {
        console.log("hellooo", res);
        if (res.data.services) {
          setServices(res.data.services);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SEARCH FOR A SERVICE
  // ______________________

  const handleServiceSearch = async (e) => {
    console.log("hhhhh", e);
    AxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL}services/search/service`,
      {
        service_name: searchService,
      }
    )
      .then((res) => {
        console.log("Data response", res);
        let temp = [];
        temp.push({
          service_name:res?.data?.services?.service_name,
          price:res?.data?.services?.price,
          duration:res?.data?.services?.duration

        })
        setServices(temp)
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  return (
    <Layout>
      <div className="services__wrapper">
        <h1 className="service__title font-montserrat main__title">Services</h1>
      </div>
      <br />
      <div className="row">
        <Search
          placeholder="Search..."
          onChange={(e) => {
            console.log("searched data", e.target.value);
            // handleServiceSearch(e.target.value);
            console.log("e.target.value=",e.target.value);
            setSearchservice(e.target.value);

          }}
          onBlur={handleServiceSearch}

        />
      </div>
      <div className="row">
        {services.length > 0 && (
          <>
            {services.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3"
                >
                  <ServiceCard
                    service_name={item.service_name}
                    description={item.service_description}
                    duration={item.duration}
                    amount={item.price}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </Layout>
  );
}

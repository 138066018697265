import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import MainLoader from "./stories/mainLoader/mainLoader";
import ProtectedRoute from "./components/ProtectedRoute/protectedRoute";
import { isLoggedIn } from "./utils/isLoggedIn";
import { useSelector } from "react-redux";
import withClearCache from "./Hoc/ClearCache";
import Services from "./pages/services";
import ServiceCard from "./stories/ServiceCard/ServiceCard";
import Service_booknow from "./pages/service_booknow";

const Home = React.lazy(() => import("./pages/home"));
const AboutUs = React.lazy(() => import("./pages/aboutUs"));
const BlogList = React.lazy(() => import("./pages/blogList"));
const SingleBlog = React.lazy(() => import("./pages/singleBlog"));
const AddJob = React.lazy(() => import("./pages/addJob"));
const Login = React.lazy(() => import("./pages/login"));
const MyAccount = React.lazy(() => import("./pages/myAccount"));
const Register = React.lazy(() => import("./pages/register"));
const AddCompanyWithServices = React.lazy(() =>
  import("./pages/addCompanyWithService")
);
const ForgotPassword = React.lazy(() => import("./pages/forgotPassword"));
const EventList = React.lazy(() => import("./pages/eventList"));
const ContactUs = React.lazy(() => import("./pages/contactUs"));
const MembersListing = React.lazy(() => import("./pages/membersListing"));
const JobList = React.lazy(() => import("./pages/jobList"));
const GalleryPage = React.lazy(() => import("./pages/gallery"));
const SponsorsList = React.lazy(() => import("./pages/sponsorsList"));
const Company = React.lazy(() => import("./pages/company"));
const CompanyList = React.lazy(() => import("./pages/companyList"));
const UserProfile = React.lazy(() => import("./pages/userProfile"));
const OpportunityList = React.lazy(() => import("./pages/opportunityList"));
const EditUserProfile = React.lazy(() => import("./pages/editUserProfile"));
const JoinDbb = React.lazy(() => import("./pages/joinDbb"));
const EditCompany = React.lazy(() => import("./pages/editCompany"));
const ReviewsList = React.lazy(() => import("./pages/reviewsList"));
const EventDetails = React.lazy(() => import("./pages/eventDetails"));
const AllUserProfile = React.lazy(() => import("./pages/AllUserProfile"));
const CompanyProfile = React.lazy(() => import("./pages/companyProfile"));

const ClearCacheComponent = withClearCache(App);

function MyApp() {
  return <ClearCacheComponent />;
}

require("dotenv").config();
function App() {
  const [auth, setAuth] = useState(false);
  const userState = useSelector((state) => state.user.user);
  useEffect(() => {
    const a = isLoggedIn();
    setAuth(a);
  }, []);
  return (
    <Router>
      <Switch>
        <React.Suspense fallback={<MainLoader />}>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
          <Route exact path="/blog-list">
            <BlogList />
          </Route>
          <Route exact path="/blogdetails/:id">
            <SingleBlog />
          </Route>
          <Route exact path="/addjob">
            <AddJob />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          {/* <Route exact path="/myAccount">
            <MyAccount />
          </Route> */}
          <ProtectedRoute
            exact
            path="/myAccount"
            component={MyAccount}
            isAuth={isLoggedIn()}
          />
          <Route exact path="/register">
            <Register />
          </Route>
          {/* <Route exact path="/addcompanywithservices">
            <AddCompanyWithServices />
          </Route> */}
          <ProtectedRoute
            exact
            path="/addcompanywithservices"
            component={AddCompanyWithServices}
            isAuth={auth}
          />
          <Route exact path="/forgotpassword/:token">
            <ForgotPassword />
          </Route>
          <Route exact path="/event-list">
            <EventList />
          </Route>
          <Route exact path="/contact-us">
            <ContactUs />
          </Route>
          <Route exact path="/members">
            <MembersListing />
          </Route>
          {/* <ProtectedRoute
            exact
            path="/members"
            component={MembersListing}
            isAuth={auth}
          /> */}
          <Route exact path="/job-list">
            <JobList />
          </Route>
          <Route exact path="/gallery">
            <GalleryPage />
          </Route>
          <Route exact path="/sponsors-list">
            <SponsorsList />
          </Route>
          {/* <Route exact path="/company/:id">
            <CompanyProfile />
          </Route> */}
          <ProtectedRoute
            exact
            path="/company/:id"
            component={CompanyProfile}
            isAuth={auth}
          />
          <Route exact path="/company-list">
            <CompanyList />
          </Route>
          {/* <ProtectedRoute
            exact
            path="/company-list"
            component={CompanyList}
            isAuth={auth}
          /> */}
          {/* <Route exact path="/company-list">
            <CompanyList />
          </Route> */}
          {/* <Route exact path="/profile">
            <UserProfile />
          </Route> */}
          <ProtectedRoute
            exact
            path="/profile"
            component={UserProfile}
            isAuth={isLoggedIn()}
          />
          <Route exact path="/opportunities">
            <OpportunityList />
          </Route>
          {/* <Route exact path="/edit-profile">
            <EditUserProfile />
          </Route> */}
          <ProtectedRoute
            exact
            path="/edit-profile"
            component={EditUserProfile}
            isAuth={auth}
          />
          <Route exact path="/joindbb">
            <JoinDbb />
          </Route>
          <ProtectedRoute
            exact
            path="/editcompany/:id"
            component={EditCompany}
            isAuth={auth}
          />
          {/* <Route exact path="/editcompany/:id">
            <EditCompany />
          </Route> */}
          <Route exact path="/reviews">
            <ReviewsList />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route exact path="/bookNow">
            <Service_booknow />
          </Route>
          <Route exact path="/ccaarrdd">
            <ServiceCard />
          </Route>
          {/* <Route exact path="/event/:id">
            <EventDetails />
          </Route> */}
          <ProtectedRoute
            exact
            path="/event/:id"
            component={EventDetails}
            isAuth={auth}
          />
          <ProtectedRoute
            exact
            path="/profile/:id"
            component={AllUserProfile}
            isAuth={auth}
          />
          {/* <Route exact path="/profile/:id">
            <AllUserProfile />
          </Route> */}
        </React.Suspense>
      </Switch>
    </Router>
  );
}

export default MyApp;

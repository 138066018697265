import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "./search.scss";
import { useEffect, useState } from "react";

export default function Search(props) {
  const {
    label,
    placeholder,
    onChange,
    result,
    onItemClick,
    imagePool,
    onKeyPress,
    onBlur,
  } = props;
  // console.log("search component : ", result);

  const [isRes, setIsRes] = useState(true);

  useEffect(() => {
    document.body.addEventListener("click", function (e) {
      if (e.target.dataset.name !== "searchres") {
        setIsRes(false);
      }
    });

    return () => {
      document.body.removeEventListener("click", function () {
        console.log("removed search result");
      });
    };
  }, []);

  useEffect(() => {
    setIsRes(true);
  }, [result]);

  return (
    <div className="eventlist__search-wrapper  position-relative font-montserrat">
      <small className="mr-3 eventlist__search-lable">{label}</small>
      <Input
        name="search"
        placeholder={placeholder}
        className="eventlist__search-input"
        suffix={<SearchOutlined />}
        onChange={onChange}
        allowClear={true}
        onKeyDownCapture={onKeyPress}
        size="large"
        autoComplete="off"
        onBlur={onBlur}
      />
      {result && result.length > 0 && isRes && (
        <div
          data-name="searchres"
          className="eventlist__search-resultbox position-absolute"
        >
          {result.map((item) => {
            return (
              <div
                onClick={() => onItemClick(item.id)}
                key={item.img}
                className="eventlist__search-resultbox-item-wrapper d-flex align-items-center justify-content-between mt-2"
              >
                <img
                  src={item.img}
                  alt="img"
                  className="img-fluid eventlist__search-resultbox-img"
                />
                <div className="eventlist__search-resultbox-item">
                  <span>{item.text.slice(0, 30)}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export const isLoggedIn = () => {
  // console.log("called");
  if (window.localStorage.hasOwnProperty("token")) {
    // console.log("true");
    return true;
  } else {
    // console.log("false");
    return false;
  }
};

import { Container, Row } from "react-bootstrap";
import FHeader from "../stories/FHeader/Header";
import { SHeader } from "../stories/SHeader/Header";
import { Footer } from "../stories/Footer/Footer";
import React from "react";

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <FHeader />
      <SHeader />
      {children}
      <Footer />
    </React.Fragment>
  );
}

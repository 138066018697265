import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../../utils/isLoggedIn";
import { message } from "antd";

function ProtectedRoute({ isAuth: isAuth, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn()) {
          return <Component />;
        } else {
          message.warning("Please Login or Register");
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            ></Redirect>
          );
        }
      }}
    ></Route>
  );
}

export default ProtectedRoute;

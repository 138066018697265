import React from "react";
import { Modal, Button, Form, Input } from "antd";
export default function Service_booknow() {
  const [bookservice] = Form.useForm();
  return (
    <div className="">
      <Modal>
        <div className="text-center">
          <h5 className="text-center"> Book a service</h5>
        </div>
        <Form>
          <strong>Name:</strong>
          <span className="text-danger">*</span>
          <Form.Item
            name="jobtitle"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input autoComplete="off" size="large"/>
          </Form.Item>
          <strong>Email:</strong>
          <span className="text-danger">*</span>
          <Form.Item
            name="jobemail"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
              {
                // pattern: emailRegex,
                message: "Invalid email",
              },
            ]}
          >
            <Input autoComplete="off" size="large"/>
          </Form.Item>
          <strong>Phone-No:</strong>
          <span className="text-danger">*</span>
          <Form.Item
            name="jobtitle"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input autoComplete="off" size="large" />
          </Form.Item>
          <strong>Description:</strong>
          <span className="text-danger">*</span>
          <Form.Item
            name="jobtitle"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input autoComplete="off" size="large" />
          </Form.Item>
          <Form.Item name="service_submit" className="text-center mt-3">
            <Button type="primary" size="large" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

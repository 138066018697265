import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./header.scss";
import { Divider } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../redux/user/userSlice";
// import { useHistory } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { isLoggedIn } from "../../utils/isLoggedIn";
import { useEffect } from "react";

export default function FHeader() {
  useEffect(() => {
    if (isLoggedIn && localStorage.hasOwnProperty("dbb_user")) {
      const localUser = JSON.parse(localStorage.getItem("dbb_user"));
      // console.log("local storage item : ", localUser);
      dispatch(setUser(localUser));
    }
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();
  // const user = useSelector((state) => state.user.user);
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    localStorage.removeItem("dbb_user");
    dispatch(setUser(null));
    history.push("/login");
  };
  // console.log("redux user", user);
  return (
    <Container fluid>
      <Row className="fHeader__wrapper text-white py-3 align-items-center justify-content-center">
        <Col className="col-12">
          <div className="d-flex justify-content-center justify-content-md-between align-items-center flex-wrap">
            <div className="fHeader__left d-flex">
              <div className="px-1 font-poppins">Reach out At </div>
              <div className="px-2 font-poppins">
                +971 42834688, +971 505993015
              </div>
            </div>
            <div className="fHeader__right ml-auto ml-md-0 d-flex align-items-center">
              {isLoggedIn() ? (
                <React.Fragment>
                  <Link
                    to="/myAccount"
                    className="px-1 fHeader__rightText font-poppins"
                  >
                    My Account
                  </Link>
                  |
                  <Link
                    to="/profile"
                    className="px-1 fHeader__rightText font-poppins"
                  >
                    Profile
                  </Link>
                  <Divider type="vertical" style={{ background: "white" }} />
                  <div
                    className="px-1 fHeader__rightText font-poppins"
                    onClick={handleLogout}
                  >
                    Logout
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Link
                    to="/login"
                    className="px-1 fHeader__rightText font-poppins"
                  >
                    Login
                  </Link>
                  <Divider type="vertical" style={{ background: "white" }} />
                  <div
                    onClick={() => history.push("/register")}
                    className="px-1 fHeader__rightText font-poppins"
                  >
                    Register
                  </div>
                </React.Fragment>
              )}
              <Divider type="vertical" style={{ background: "white" }} />
              <a
                href="https://www.linkedin.com/company/dubai-business-breakfast"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="fHeader__rightText"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAzUlEQVQokcWRMUpDQRRFz9PfmGgQUgaErMHKJqsIWNm6EAshlYV7cA9inS4pbIKEtB8CFqKFFkGOhRMYA4GZygNT3Dvv8i48SKg9alEH6spfntSmJnzpX4Y14Y76oC7V25rWDTAEntND7QNj4DTNvABLYAR0gMeIWGw3X+/UPlfbTH+o35neqFcABwXtTnbmGuBOPS4JfwL3wDTz+sBFyVkmEXGjHgGvQDf5ZyWb1wAR8QW8Z/5hSXgv/xdugBaYZ94bMAMGSbfZ3z6/nh/gW4gH86reSAAAAABJRU5ErkJggg=="
                  alt="logo"
                  // onClick={() =>
                  //   (window.location.href =
                  //     "https://www.linkedin.com/company/dubai-business-breakfast")
                  // }
                />
              </a>
              &nbsp;
              <a
                href="https://www.facebook.com/dubaibusinessbreakfast/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="fHeader__rightText"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAqUlEQVQokc2SMQrCQBBF34hXkBR2Fp5DjyKirUcSL2JhZWNhaSOCdoLYBUF8NgtCNDGSxt/t7Jt9M7DQIFF1qQ6BMZABh4iY1HpVHaoPXzkVmXZF/yhNdgVmwKWWNZmXybgqY97M6gDoA91UytQpcIuIxTfj3M/ZFdlW7T3g+JVQO2pPXSfjOp2zIvu2c0ScgbOap1IeEftPol/G/qPmqh+2Ae7AtomgNE8VOG1Q54bWowAAAABJRU5ErkJggg=="
                  alt="logo"
                  // onClick={() =>
                  //   (window.location.href =
                  //     "https://www.facebook.com/dubaibusinessbreakfast/")
                  // }
                />
              </a>
              {/* <img
                className="mx-3 fHeader__rightText"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAA50lEQVQokbXQvytFYRzH8c/RVYpBkVIm/gCLxWC6A4vdgL9BGU3kj2CwWO/EpMRklwwWxULKpCiiXpYjp+Me7uJdT099nt7P90fy32ASbYxWsiIYx2KDVGDHN6/YxC7mgiW8YaWLvKo7l1gL5ivhIWYr8n6DfILBoB8XtcdrdHDVIK8nSSvJQpL3WsdT5Wni7qu1YTw2VGhiorqYmXIJvXD25fWV932SoV/arLL9I8Eynv6outf4JaZx0yAeYKAutLGFI3x0kV6wgVa9WCvJaTn7WJLzJCNJnpPcJjlO0imK4qHHffTOJ8KjnjdnBTZDAAAAAElFTkSuQmCC"
                alt="logo"
              /> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Modal, Button, Form, Input } from "antd";
import { useState } from "react";
import React from "react";
import AxiosInstance from "../../utils/axiosInstance";
import "./serviceCard.scss";
const ServiceCard = (props) => {
  const { service_name, amount, description } = props;
  const [BookService, setBookService] = useState(false);
  const [bookForm] = Form.useForm();
  const [serviceData, setServiceData] = useState({
    name: null,
    phone: null,
    email: null,
    description: null,
  });

  //BOOK A SERVICE


  const addData = () => {
    // setIsUploading(true);
    // setUploadMessage("Addng Your Job Please wait ...");
    // setUploadMessage("ADDING YOUR JOB PLEASE WAIT...........");
    const data = {
      name: serviceData.name,
      email: serviceData.email,
      phone: serviceData.phone,
      description: serviceData.description,
    };
    AxiosInstance.post("services/bookService", data).then((res) => {
      // if (res.status === 201 || res.status === 200){
        // message.success("New job added");
        // jobForm.resetFields();
        // setIsUploading(false);
        // setIsAddJobModal(false);
        // getJobs();
      // } else {
        // message.error("Oops something went wrong please try again later !");
      // }
      console.log("add job res : ", res);
    });
  };
  return (
    <div className="servicecard__wrapper w-100">
      <div className="text-right">
        {amount &&(<> <strong className="servicecard__price">{amount}</strong></>)}
      </div>
      <div className="servicecard__body mt-3">
        <h6 className="servicecard__title">{service_name}</h6>
        <div className="servicecard__location">
          <span className="text-muted">{service_name.toUpperCase()}</span>
          {/* <span className="text-muted"> | </span> */}
          {/* <span className="text-muted">{location}</span> */}
          {/* <span className="text-muted">{location}</span> */}
        </div>
        <div className="servicecard__text mt-1">
          <p className="text-muted">{description}</p>
        </div>
        <div className="text-right">
          <span className="text-muted">
            <Button
              className="button-booknow"
              onClick={() => setBookService(true)}
            >
              Book Now
            </Button>
          </span>
        </div>
        {/* booknow start */}
        <Modal
          footer={null}
          visible={BookService}
          onCancel={() => setBookService(false)}
          destroyOnClose={true}
        >
          <div className="text-center">
            <h5 className="text-center"> Book a service </h5>
          </div>
          <Form name="book__now" form={bookForm} onFinish={addData}>
            <strong>Name:</strong>
            <span className="text-danger">*</span>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input autoComplete="off" size="large" />
            </Form.Item>
            <strong>Email:</strong>
            <span className="text-danger">*</span>
            <Form.Item
              name="useremail"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  // pattern: emailRegex,
                  message: "Invalid email",
                },
              ]}
            >
              <Input autoComplete="off" size="large" />
            </Form.Item>
            <strong>Phone-No:</strong>
            <span className="text-danger">*</span>
            <Form.Item
              name="userphoneno"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input autoComplete="off" size="large" />
            </Form.Item>
            <strong>Description:</strong>
            <span className="text-danger">*</span>
            <Form.Item
              name="servicedescription"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input autoComplete="off" size="large" />
            </Form.Item>
            <Form.Item name="service_submit" className="text-center mt-3">
              <Button type="primary" size="large" htmlType="submit">
                OK
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default ServiceCard;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import TempImage1 from "../../assets/img/site-img16.jpg";
import TempImage2 from "../../assets/img/site-img17.jpg";
import { RightOutlined } from "@ant-design/icons";
import "./footer.scss";
import { Link, useHistory } from "react-router-dom";
import AxiosInstance from "../../utils/axiosInstance";
import { useState, useEffect } from "react";
import { isLoggedIn } from "../../utils/isLoggedIn";

export const Footer = () => {
  const [blogs, setBlogs] = useState(null);
  const [tags, setTags] = useState(null);
  useEffect(() => {
    getBlogs();
    getTags();
  }, []);
  const getBlogs = () => {
    let arr = [];
    const now = new Date();
    try {
      AxiosInstance.get("news/list").then((res) => {
        // console.log("blogs res from footer :: ", res.data.data);
        if (res.data.success) {
          setBlogs(res.data.data.slice(0, 2));
        }
      });
    } catch (err) {
      console.error("error while getting blogs :: ", err);
    }
  };

  const getTags = () => {
    AxiosInstance.get("news/list/news-categories")
      .then((res) => {
        if (res.data.success) {
          setTags(res.data.data);
        }
        // console.log("news categories footer: ", res.data.data);
      })
      .catch((err) => {
        console.error("error while getting categories : ", err);
      });
  };
  // console.log("recent footer posts : ", blogs);
  const history = useHistory();
  return (
    <Container fluid>
      <Row className="justify-content-center footer__wrapper py-5 pl-md-0 pl-4 font-montserrat">
        <Col md={10} xs={12}>
          <Row>
            <Col md={3} xs={12} className="footer__frstRow">
              <div>
                <img src={Logo} alt="" />
              </div>
              <div className="footer__address mt-2">
                Dubai Business Breakfast (DBB) Palladium Business Center, 4
                Floor, Al Barsha 1 Al Barsha, Dubai, UAE
              </div>

              {/* <div className="d-flex my-3">
              <img
                alt="svgImg"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTUiIGhlaWdodD0iMTUiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzcyNzI3MiI+PHBhdGggZD0iTTg2LDguMDYyNWwtODMuMzEyNSw2Ny4xODc1bDEuMzIyNzUsMS43NTg0MmMzLjY3MzgxLDQuODk5MzEgMTAuNjg4OTQsNS43NDQyOCAxNS40MjE2MywxLjg1ODE1bDY2LjU2ODEyLC01NC42NzkwOGw2Ni41NjgxMSw1NC42NzkwOGM0LjczMjY5LDMuODg2MTIgMTEuNzQ3ODIsMy4wNDExNiAxNS40MjE2MywtMS44NTgxNWwxLjMyMjc2LC0xLjc1ODQybC0yNC4xODc1LC0xOS41MDUzN3YtMzQuMjQ0NjNoLTI0LjE4NzV2MTQuNzM5MjZ6TTg2LDM0LjkzNzVsLTY0LjUsNTEuMDYyNXY2NC41aDEyOXYtNTYuNDM3NXpNNjkuODc1LDkxLjM3NWgzMi4yNXY0OC4zNzVoLTMyLjI1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+"
              />
              <div className="footer__contact mx-2">Phone: +971 4 2834688</div>
            </div> */}
              <div className="d-flex my-3">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAABBklEQVQokaXPsUrEQBAG4H8WxMYU11hZ+AJWh72N1rZaiX2KZVPsG4jFZpC8gQfXWdhqIT6AFvYiKCrYRxDZ/DYJ3IUcbPBvdpjhW2aAf0S6oiiKzRjjhoj8lmX5loINAHjvJ03TfIjIM4BXa+1BMq7r+rurAcAYs52Mq6r6AfDVNUnuJuM2t+3bkLwbhUXkqi2tqs5H4RDCNYBHADspcAkDoDHGAThxzh2lYOk3nHOW5BnJ0259a+2xiOwBeCD5oqo3g3jhg3MAlwCeAOjiliS3VPXdDOEQgpLcF5EpgIveeSC51r95Kap6n2XZlOQhyRmAz3YUY4z1KrcyeZ6ve+8no+FQ/gBjPGfq02CNRQAAAABJRU5ErkJggg=="></img>
                <div className="footer__contact mx-2">
                  Phone: +971 42834688,
                </div>
              </div>
              <div>
                <div>+971 505993015</div>
              </div>
              {/* <div className="d-flex my-3">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAABe0lEQVQokY2QvWtTURiHn9/toTFW/CpIW+jit4MUoaCupf0XOoloB7eIySUXx4xZcrIF6topOPgfiJOLoFRwaSuCDh20FC4uCSfndfAGoeTGvtN7eM5z3t95RUk1m82FEMLZSaxSqRy32+1jVyaPRiMv6fsEdG44HP4CWi5N076ZrQKY2c/BYLDe6/V+A7n3/uVJM8uypRDCMwBnZptjIOlqtVq9lWXZXghhriwVcKnVas0mE8CVEMIhsDZFfp7n+e6kPy8Dc8CZRqPx9SQMIcwU7e3ShQEzwA/gmyQzs2vAW+Dp+MI0GWARSOxvLUq6C1w4lWxmnyV9AawQ+8Ad4OJ/ZUlrwL3ieBm4Dtw4bew3ZvZRkgGrZrYt6RWwAuAkHZnZ/L+ktg8YIGBL0uPxIEmPgNki1ZGLMT5IkuRh8Vq/0+l8SNP0PvDEzN6VRYoxfnLdbvcAOKjX6xsxxrxgN4E97/3rqTsZN7Va7bxz7oWkJeC9936niF9afwBgxY606rvBMgAAAABJRU5ErkJggg==" />
              <div className="footer__contact mx-2">Fax: + 1(234) 547 8901</div>
            </div> */}
              <div className="d-flex my-3">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAABGElEQVQokcWRMUuCYRSFn/MpEqS4S0NTDbVEhNDQ0NbWUEM01hzp4BY0F+LsFNRQS5sFQj/AqQz6C7UVKF/Tq5wWLZHMhKCz3vNwzr0X/ksCKJVKuRDCjqTkOMB2B7iqVCrPSYAQwoakEyAamyZ1JL0AlwmARqPxkM/na5KWgdwPYFPSZrlcrtNPKhQK1UQisRDH8artEvA+xMWSDjOZzAqwVCwWLz5hYM72eTqdvrNdsz0PXPd2vJG0GEXRbavVqtuu2p4ZhPtai6LoXtJ+CGFX0mw2m92yvdftdp8krQ+avzvQFHCcSqUebW+32+1H4AhIDRtHvqZX/XTUfFTyr9WH3yaBJL3CV+0DoAlMjwNtx8DZJGF/rw+27WR7G5wjBQAAAABJRU5ErkJggg==" />
                <div className="footer__contact mx-2">
                  dubaibusinessbreakfast@gmail.com
                </div>
              </div>
            </Col>
            <Col md={3} xs={12}>
              <div className="footer__headings">RECENT POSTS</div>
              <Col xs={2} className="m-0 p-0 pr-3">
                <div className="footer__headings__style"></div>
              </Col>
              {blogs &&
                blogs.length > 0 &&
                blogs.map((item) => {
                  return (
                    <div
                      key={item.news_id}
                      onClick={() =>
                        history.push(`/blogdetails/${item.news_id}`)
                      }
                      className="footer__recentPostsWrapper d-flex justify-content-start align-items-center my-3"
                    >
                      <div className="footer__recentPostsWrapper_imgWrapper ">
                        <img
                          className="footer__recentPostsWrapper_img rounded-circle img-fluid"
                          src={`${process.env.REACT_APP_IMAGE_URL}news/${item.news_image}`}
                          alt={item.news_image_alt}
                        />
                      </div>
                      <div className="footer__recentPost ml-3">
                        <div className="footer__recentPostTitle text-white">
                          {item.news_title.slice(0, 15)}{" "}
                          {item.news_title.length > 15 ? ".." : null}
                        </div>
                        <div className="footer__recentPostSubTitle">
                          {item.news_short_description.slice(0, 8)}{" "}
                          {item.news_short_description.length > 8
                            ? "..."
                            : null}
                        </div>
                        <div className="footer__recentPostAuthor">
                          {new Date(item.updated_at).getMonth()}
                          {"/"}
                          {new Date(item.updated_at).getDay()}
                          {"/"}
                          {new Date(item.updated_at).getFullYear()}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Col>
            <Col md={3} xs={12}>
              <div className=" footer__headings">TAGS</div>
              <Col xs={2} className="m-0 p-0 pr-3">
                <div className="footer__headings__style"></div>
              </Col>
              <div className="my-4 footer__tagsWrapper d-flex flex-wrap">
                {tags &&
                  tags.length > 0 &&
                  tags.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        onClick={() => {
                          history.push("/blog-list");
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className="footer__tagBox py-1 px-3 m-1"
                      >
                        {item.news_cat_name}
                      </div>
                    );
                  })}
              </div>
            </Col>
            <Col md={3} xs={12}>
              <div className=" footer__headings">USEFUL LINKS</div>
              <Col xs={2} className="m-0 p-0 pr-3">
                <div className="footer__headings__style"></div>
              </Col>
              <div className="footer__usefullLinksWrapper my-4 row">
                <div className="col-6 px-0">
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/")}
                      className="footer__usefullLinks"
                    >
                      Home
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/contact-us")}
                      className="footer__usefullLinks"
                    >
                      Contact Us
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("about-us")}
                      className="footer__usefullLinks"
                    >
                      About Us
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/event-list")}
                      className="footer__usefullLinks"
                    >
                      Events
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/reviews")}
                      className="footer__usefullLinks"
                    >
                      Reviews
                    </div>
                  </div>
                  {isLoggedIn() && (
                    <div className="d-flex align-items-center my-2">
                      <RightOutlined
                        style={{ color: "#727272", fontSize: "12px" }}
                      />
                      <div
                        onClick={() => history.push("/members")}
                        className="footer__usefullLinks"
                      >
                        Members
                      </div>
                    </div>
                  )}
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/gallery")}
                      className="footer__usefullLinks"
                    >
                      Gallery
                    </div>
                  </div>
                </div>
                <div className="col-6 px-0">
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/joindbb")}
                      className="footer__usefullLinks"
                    >
                      Join DBB
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/sponsors-list")}
                      className="footer__usefullLinks"
                    >
                      Sponsors & Membership
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/opportunities")}
                      className="footer__usefullLinks"
                    >
                      Business Opportunities
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/job-list")}
                      className="footer__usefullLinks"
                    >
                      Job Opportunities
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <RightOutlined
                      style={{ color: "#727272", fontSize: "12px" }}
                    />
                    <div
                      onClick={() => history.push("/blog-list")}
                      className="footer__usefullLinks"
                    >
                      News & Media
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center footer__wrapperBott py-5">
        <Col>
          <Row>
            <Col className="d-flex justify-content-center">
              <a
                href="https://www.facebook.com/dubaibusinessbreakfast/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="p-2 rounded-circle footer__bottomIcons mx-2">
                  {" "}
                  <img
                    alt="facebook"
                    className=""
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAqUlEQVQokc2SMQrCQBBF34hXkBR2Fp5DjyKirUcSL2JhZWNhaSOCdoLYBUF8NgtCNDGSxt/t7Jt9M7DQIFF1qQ6BMZABh4iY1HpVHaoPXzkVmXZF/yhNdgVmwKWWNZmXybgqY97M6gDoA91UytQpcIuIxTfj3M/ZFdlW7T3g+JVQO2pPXSfjOp2zIvu2c0ScgbOap1IeEftPol/G/qPmqh+2Ae7AtomgNE8VOG1Q54bWowAAAABJRU5ErkJggg=="
                  />
                </div>
              </a>
              {/* <div className="p-2 rounded-circle footer__bottomIcons mx-2">
                {" "}
                <img
                  className=" "
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAA50lEQVQokbXQvytFYRzH8c/RVYpBkVIm/gCLxWC6A4vdgL9BGU3kj2CwWO/EpMRklwwWxULKpCiiXpYjp+Me7uJdT099nt7P90fy32ASbYxWsiIYx2KDVGDHN6/YxC7mgiW8YaWLvKo7l1gL5ivhIWYr8n6DfILBoB8XtcdrdHDVIK8nSSvJQpL3WsdT5Wni7qu1YTw2VGhiorqYmXIJvXD25fWV932SoV/arLL9I8Eynv6outf4JaZx0yAeYKAutLGFI3x0kV6wgVa9WCvJaTn7WJLzJCNJnpPcJjlO0imK4qHHffTOJ8KjnjdnBTZDAAAAAElFTkSuQmCC"
                />
              </div> */}
              <a
                href="https://www.linkedin.com/company/dubai-business-breakfast"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="p-2 rounded-circle footer__bottomIcons mx-2">
                  {" "}
                  <img
                    alt="linkedin"
                    className=""
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAzUlEQVQokcWRMUpDQRRFz9PfmGgQUgaErMHKJqsIWNm6EAshlYV7cA9inS4pbIKEtB8CFqKFFkGOhRMYA4GZygNT3Dvv8i48SKg9alEH6spfntSmJnzpX4Y14Y76oC7V25rWDTAEntND7QNj4DTNvABLYAR0gMeIWGw3X+/UPlfbTH+o35neqFcABwXtTnbmGuBOPS4JfwL3wDTz+sBFyVkmEXGjHgGvQDf5ZyWb1wAR8QW8Z/5hSXgv/xdugBaYZ94bMAMGSbfZ3z6/nh/gW4gH86reSAAAAABJRU5ErkJggg=="
                  />
                </div>
              </a>
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="text-center">
              Copyright © 2021 DBB By{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.reontel.com/"
                className="text-muted"
              >
                Reon Technologies
              </a>{" "}
              | All rights reserved
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
